<template>
  <div
    ref="provider"
    class="TextInput"
    :class="{
      'mt-8': label && size === 'lg',
      'mt-6': label && size === 'md',
    }"
    tag="div"
    :name="name"
    :vid="id"
  >
    <div class="relative">
      <slot v-if="$slots.icon" name="icon" />

      <input
        v-if="type !== 'textarea'"
        :id="id"
        ref="textInputRef"
        v-model="fieldValue"
        :name="name"
        class="w-full h-full leading-normal placeholder:font-normal"
        :class="{
          'border border-red-700': border && errorMessage,
          'border border-secondary-50': border && !errorMessage,
          'border border-white rounded-2xs placeholder:text-white  bg-transparent text-white': inverted,
          'rounded-2xl': rounded,
          'has-value': hasValue,
          'pl-10': $slots.icon,
          'px-3': !$slots.icon,
          'pl-11': errorMessage,
          'py-3 md:py-4': size === 'lg',
          'py-3': size === 'md',
          'py-2': size === 'sm',
        }"
        :type="showPassword ? 'text' : type"
        :placeholder="placeholder"
        v-bind="$attrs"
        :autocomplete="autocomplete"
        @focus="emit('focus')"
        @blur="emit('blur')"
      />
      <textarea
        v-if="type === 'textarea'"
        :id="id"
        ref="textareaRef"
        v-model="fieldValue"
        :name="name"
        :type="showPassword ? 'text' : type"
        :placeholder="placeholder"
        v-bind="$attrs"
        class="w-full h-full leading-normal"
        :class="{
          'border border-red-700': border && errorMessage,
          'border border-gray-100': border && !errorMessage,
          'border border-white rounded-md placeholder:text-white  text-white': inverted,
          'rounded-md': rounded,
          'has-value': hasValue,
          'pl-10': $slots.icon,
          'px-3': !$slots.icon,
          'pl-11': errorMessage,
          'py-4': size === 'lg',
          'py-2': size === 'sm',
        }"
        @focus="emit('focus')"
        @blur="emit('blur')"
      ></textarea>

      <button
        v-if="type === 'password'"
        type="button"
        class="w-5 h-5 absolute right-5 z-10 top-1/2 transform -translate-y-1/2"
        @click="showPassword = !showPassword"
      >
        <svg-icon
          :name="showPassword ? 'eye' : 'eye-hide'"
          :width="24"
          :height="24"
          :filled="false"
          class="text-primary-A900"
        />
      </button>

      <svg-icon
        v-if="errorMessage"
        name="error"
        class="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 z-20 fill-current text-danger"
        :class="[type === 'password' ? 'right-14' : 'right-3']"
      />

      <div class="absolute -bottom-6 right-0 flex items-center">
        <slot name="below-hint" />
      </div>
    </div>

    <label class="TextInput__label" :for="id">
      <slot name="label" />
      <template v-if="!$slots.label">
        <span>{{ label }}</span>
        <span v-if="required" class="text-gray-500">{{ required ? ' *' : '' }}</span>
      </template>
    </label>

    <span v-if="errorMessage" class="TextInput__error">{{ errorMessage || '' }}</span>

    <div v-else-if="$slots.hint" class="TextInput__hint">
      <slot name="hint" />
    </div>
    <div v-else>&nbsp;</div>

    <slot name="after" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  // Field Props
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  autocomplete: {
    type: String,
    default: '',
  },
  // Icon Prop
  icon: {
    type: String,
    default: '',
  },
  // UI behavioral props
  required: { type: Boolean, default: false },
  // UI Props
  rounded: {
    type: Boolean,
    default: true, // setting this to true as this is the common ui behavior in the design
  },
  border: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String as PropType<'lg' | 'sm' | 'md'>,
    default: 'lg',
  },
  // Composite UI props to assign multiple values based on this value
  inverted: {
    type: Boolean,
    default: false,
  },
});

const nameRef = toRef(props, 'name');

const { errorMessage, value: fieldValue } = useField<string>(nameRef.value);

const showPassword = ref(false);

const hasValue = computed(() => {
  return fieldValue?.toString().length;
});

const emit = defineEmits(['focus', 'blur', 'input']);

const textInputRef = ref<HTMLInputElement | null>(null);
const textareaRef = ref<HTMLTextAreaElement | null>(null);

const forceFocus = () => {
  if (props.type !== 'textarea' && textInputRef.value) {
    nextTick(() => {
      textInputRef.value?.focus();
    });
  }

  if (props.type === 'textarea' && textareaRef.value) {
    nextTick(() => {
      textareaRef.value?.focus();
    });
  }
};

defineExpose({
  forceFocus,
});
</script>

<style lang="postcss" scoped>
.TextInput {
  @apply relative flex flex-col appearance-none w-full;
  input,
  textarea {
    position: relative;
  }

  &__label {
    @apply absolute block -top-6 inset-x-0 w-full leading-normal text-sm text-secondary-700;
    user-select: none;
  }

  &__error,
  &__hint {
    @apply mt-2 block text-red-700 text-xs left-3;
  }

  /* &__hint {
    @apply -mt-2 block text-red-700 text-sm absolute left-3;
    bottom: -5px;
  } */

  input.has-value,
  input:focus,
  textarea.has-value,
  textarea:focus {
    @apply outline-none;
  }
}

input::before {
  content: 'looks good';
  color: green;
}

:deep(.nuxt-icon svg) {
  height: 24px;
  width: 24px;
}
</style>

<i18n>
{
  "en": {
    "optional": "(OPTIONAL)"
  },
  "ar": {
    "optional": "(اختياري)"
  }
}
</i18n>
